html {
    scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.mainContainer {
    min-height: 80vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    height: fit-content !important;
}

body.light .mainContainer {
    color: #111 !important;
}

body.light .header {
    background-color: #232323 !important;
}

body.light .footer {
    background-color: #232323 !important;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

body.dark {
    background-image: linear-gradient(120deg,
            rgba(7, 7, 9, 1) 16%,
            rgba(27, 24, 113, 1) 96%) !important;
}

body.light {
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 1) 16%,
            rgba(255, 255, 255, 1) 96%) !important;
}

body.dark .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    background-color: #111 !important;
}

body.dark .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper ul li p a.routeLink {
    color: #f6af61 !important;

}


body.light .mainContainer p,
body.light .mainContainer .button,
body.light .mainContainer a,
body.light .mainContainer li.text,
body.light .mainContainer li a.text,
body.light .mainContainer span,
body.light .swiper-pagination-fraction swiper-pagination-horizontal {
    color: #222 !important;
}

#root {
    overflow: hidden;
    max-width: 100%;
}

.mainButton {
    color: #8f9296 !important;
    transition: all .5s;
    font-size: 18px !important;
    transition: all .5s;
}

.mainButton:hover {
    color: #f6af61 !important;
    border: solid 1px #f6af61;
    scale: 1.1;
}

body.dark .mainContainer .button,
a,
li.text,
li a.text {
    color: #dbdbdb !important;
}

body,
* {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 100;
}


.css-deurzj-MuiPaper-root-MuiCard-root {
    max-width: 100%;
}

.border {
    border: 1px solid #f6af61 !important;
}

.subTitle {
    padding: 10px 0px;
    border-bottom: solid 1px #232323;
    text-transform: uppercase;
    font-size: 15px !important;
}


.button {
    transition: all .5s;
    position: relative;
}

.button:hover {
    color: #232323;
    position: relative;
}

.button::after {
    content: " ";
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #232323;
    bottom: 0px;
    left: 0px;
    transition: all 1s;

}

button:hover::after {
    width: 100%;
}

.heading {
    color: #8f9296 !important;
    font-size: 1.5rem;
}

.menuID {
    color: #f6af61;
    font-size: 1.5rem;
}

.dark .title {
    color: #8f9296 !important;
}

.light .title {
    color: #232323 !important;
}

.title {
    font-weight: 300;
    font-size: 4em !important;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    text-align: center;
}


/* Starting the about Us  */
.aboutUs {
    padding: 5px !important;
    line-height: 2rem;
    margin: 10px;
    letter-spacing: 1px;
    font-size: 15px;
    display: block;
}

/* Ending the about Us  */

/* Starting the Products */

.products li {
    padding: 5px 0px;
    font-size: 13px;
}

/* end of the Products Section */

/* Starting the Work and Projects Section */

.overLay {
    position: absolute;
    width: 100%;
    display: none !important;
    transition: all 1s;
    height: 100%;
    max-height: 300px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.overLayContainer {
    position: relative;
}

.overLayContainer:hover .overLay {
    display: flex !important;
    animation-name: fadeIn;
    animation-duration: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
}


#particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -1;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

@keyframes fadeIn {
    0% {
        opacity: .2;
    }

    25% {
        opacity: .4;
    }

    50% {
        opacity: .6;
    }

    75% {
        opacity: .8;
    }

    100% {
        opacity: 1;
    }
}


@media only screen and (max-width: 899px) {
    .mainContainer {
        min-height: 50vh !important;
        padding: 20px 0px;
    }

}