.title {
    color: #fff;
    font-size: 2em !important;
}

.subTitle {
    color: #f6af61 !important;
    font-size: 30px !important
}

.text {
    color: #bababb !important;
    font-size: 1rem !important;
    text-decoration: none !important;
}

.productsList {
    list-style: none;
    padding: 10px;
}