.mySwiper {
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden;
}


.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}



.swiper-slide img {
    width: 80%;
    height: 80%;
}

.mySwiper a {
    text-decoration: none !important;
}

.catImage {
    object-fit: contain !important;
    max-height: 90%;
}