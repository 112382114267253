.icon {
    height: 100% !important;
    width: 100% !important;
    color: #fff;
}

.aboutus-desc p {
    line-height: 2rem !important;
    letter-spacing: 1px !important;
    font-size: 18px !important;
    padding: 20px;
}

.aboutus-desc h2 {
    font-size: 3em !important;
}

td {
    text-align: start !important;
}