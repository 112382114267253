.header {
  background-color: rgba(.0, .0, .0, .5) !important;
  display: flex !important;
  justify-content: center !important;

}

.routeLink {
  text-decoration: none !important;
  color: #f6af61 !important;
}



.routesList {
  display: flex;
  justify-content: space-around;
  align-items: center;
}





@media only screen and (max-width: 899px) {
  .hideLogo {
    display: none !important;
  }

  .css-1lvtzne {
    display: inline-block !important;
    flex-grow: unset !important;
  }
}