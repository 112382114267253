.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}



.mySwiper {
  min-height: 70vh;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.descrption {
  font-size: 2rem !important;
}

body.light .descrption {
  color: #222 !important;
}

body.dark .descrption {
  color: #ccc !important;
}