.nav-item a {
    font-size: 1em !important;
}

.footer {
    background: #000;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}



.footer h3 {
    color: #8f9296;
    font: normal 36px 'Roboto', cursive;
    margin: 0 0 10px;
}

.footer h3 span {
    color: #5383d3;
}

.footer .title-p {
    color: #8f9296;
    text-align: center;
}

/* Footer links */

.footer .footer-links {
    color: #8f9296;
    margin: 0 0 10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 1.5em !important;
}

.footer .footer-links li {
    margin: 0px 10px 0px 0px;
}

.footer .footer-links a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    color: #8f9296;
    font-size: 1.5rem !important;
}

.footer .footer-company-name {
    color: #8f9296;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
}


.footer .img-fluid {
    max-height: 100px;
}

.freez {
    margin-top: 10px 0px;
}

.footer .routesList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}