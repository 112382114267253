@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Montserrat:wght@300;400;500;600;700&display=swap");

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}



.content {
    padding: 0 10px;
}

.content h1 {
    line-height: 1.3;
    margin-bottom: 30px;
    color: #f6af61;
    pointer-events: none;
}